import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Regulatory Compliance">
    <section>


    </section>
  </Layout>
);

export default Page;
